export default function Page() {
  //var coll = document.getElementsByClassName("collapsible");
  //var i;
  //for (i = 0; i < coll.length; i++) {
  const accordionHandler = (item) => {
    console.log(item.target);
    item.stopPropagation();
    item.target.classList.toggle("active");
    /*var rect = e.getBoundingClientRect();
    var body = document.body;
    var html = document.documentElement;

    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    let vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );

    var content = e.nextElementSibling;
    if (content.style.maxHeight) {
      content.style.maxHeight = null;
      const scrolly = this.getAttribute("data-scrolly");
      // window.scroll({
      //   top: scrolly,
      //   behavior: "smooth",
      // });
    } else {
      console.log("expand");
      this.setAttribute("data-scrolly", window.scrollY);
      content.style.maxHeight = content.scrollHeight + "px";
      const y = content.getBoundingClientRect().top + window.scrollY;
      var my = (-vh + content.scrollHeight) / 2 + y;
      // window.scroll({
      //   top: my,
      //   behavior: "smooth",
      // });
    }*/
  };

  return (
    <>
      <div className="p-0 m-0 text-gray-800 bebas-neue-pro text-md">
        <div className="webglFrame p-0 m-0 max-h-[100vh] lg:max-w-[100vw] fixed overflow-hidden">
          <script type="module" src="/web-002-webgl.js"></script>
        </div>

        <div className="w-full top-[50vh] z-10 absolute">
          <div className="flex flex-col w-full max-w-[92vw] lg:max-w-[800px] mx-auto bg-white bg-opacity-95">
            <div className="border-b border-gray-200 bg-opacity-20">
              <div className="flex flex-row text-slate-900">
                <div className="w-full my-1 p-3">
                  <p className="font-bold text-6xl leading-tight -mb-3">COMPUTATIONAL</p>
                  <p className="text-2xl leading-tight">Artist and Developer Neil Jenkins</p>
                </div>
              </div>
            </div>

            <div
              className="border-b border-gray-200"
              data-type="reveal"
              onClick={(e) => accordionHandler(e)}
            >
              <div className="collapsible flex flex-row text-slate-900">
                <div className="w-full my-1 px-3 py-2">
                  <p className="text-[1.5rem]">About</p>
                </div>
              </div>
              <div className="collapsibleBlock flex flex-row text-gray-900">
                <div className="block">
                  <div className="w-full flex flex-col py-4 mx-auto max-w-full p-3 text-xl">
                    <p className="mb-6">
                      I am an artist who works with technology and computer code producing a range
                      of artworks from generative audio video work and interactive digital pieces to
                      physical prints and machine made drawings. I’m fascinated with science and
                      mathematics, design and the art, and these often influence the creation of
                      bodies of work. My work has been exhibited internationally and featured in a
                      number of art and media festivals, publications and academic journals.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="border-b border-gray-200" data-type="reveal">
              <div className="collapsible flex flex-row text-slate-900">
                <div className="w-full my-1 px-3 py-2">
                  <p className="text-[1.5rem]">Select works</p>
                </div>
              </div>

              <div className="collapsibleBlock">
                <div className="flex flex-row text-gray-900">
                  <div className="w-full flex flex-col md:flex-row py-8 mx-auto max-w-full p-3">
                    <div className="w-full md:w-5/12">
                      <div className="embla" data-options="{ loop: true }">
                        <div className="embla__viewport embla_a3_portrait">
                          <div className="embla__container">
                            <div className="embla__slide">
                              <div className="embla__lazy-load">
                                <span className="embla__lazy-load__spinner"></span>
                                <img
                                  className="embla__slide__img embla__lazy-load__img"
                                  src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D"
                                  alt="Fluidity - screen print on canvas"
                                  data-src="img/plot1.png"
                                />
                              </div>
                            </div>
                            <div className="embla__slide">
                              <div className="embla__lazy-load">
                                <span className="embla__lazy-load__spinner"></span>
                                <img
                                  className="embla__slide__img embla__lazy-load__img"
                                  src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D"
                                  alt="drawings"
                                  data-src="img/plot2.png"
                                />
                              </div>
                            </div>
                            <div className="embla__slide">
                              <div className="embla__lazy-load">
                                <span className="embla__lazy-load__spinner"></span>
                                <img
                                  className="embla__slide__img embla__lazy-load__img"
                                  src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D"
                                  alt="Fluidity - screen print on canvas"
                                  data-src="img/plot3.png"
                                />
                              </div>
                            </div>
                            <div className="embla__slide">
                              <div className="embla__lazy-load">
                                <span className="embla__lazy-load__spinner"></span>
                                <img
                                  className="embla__slide__img embla__lazy-load__img"
                                  src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D"
                                  alt="Samuel Beckett - After John Haynes - acrylic ink on black paper"
                                  data-src="img/plot4.png"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="embla__controls">
                          <div className="embla__buttons">
                            <button
                              className="embla__button embla__button--prev"
                              type="button"
                              disabled=""
                            >
                              <svg className="embla__button__svg" viewBox="0 0 532 532">
                                <path d="M355.66 11.354c13.793-13.805 36.208-13.805 50.001 0 13.785 13.804 13.785 36.238 0 50.034L201.22 266l204.442 204.61c13.785 13.805 13.785 36.239 0 50.044-13.793 13.796-36.208 13.796-50.002 0a5994246.277 5994246.277 0 0 0-229.332-229.454 35.065 35.065 0 0 1-10.326-25.126c0-9.2 3.393-18.26 10.326-25.2C172.192 194.973 332.731 34.31 355.66 11.354Z"></path>
                              </svg>
                            </button>
                            <button
                              className="embla__button embla__button--next"
                              type="button"
                              disabled=""
                            >
                              <svg className="embla__button__svg" viewBox="0 0 532 532">
                                <path d="M176.34 520.646c-13.793 13.805-36.208 13.805-50.001 0-13.785-13.804-13.785-36.238 0-50.034L330.78 266 126.34 61.391c-13.785-13.805-13.785-36.239 0-50.044 13.793-13.796 36.208-13.796 50.002 0 22.928 22.947 206.395 206.507 229.332 229.454a35.065 35.065 0 0 1 10.326 25.126c0 9.2-3.393 18.26-10.326 25.2-45.865 45.901-206.404 206.564-229.332 229.52Z"></path>
                              </svg>
                            </button>
                          </div>
                          <div className="embla__dots"></div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full md:w-7/12 md:px-3">
                      <div className="">
                        <div className="w-full pl-4">
                          <h3 className="text-2xl pb-8">Machine drawings</h3>
                          <p className="text-xl">
                            Generative and computational drawings developed in code and drawn by a
                            computer controlled pen plotter.
                          </p>
                          <p className="text-xl">
                            Derived from procedural algorithms, elevation maps, digital images and
                            other data sets, these works forge a connection between drawing and the
                            virtual - translating/mapping bitmaps as mazes, topograpical data as
                            contours
                          </p>
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row text-gray-900">
                  <div className="w-full flex flex-col md:flex-row py-8 mx-auto max-w-full p-3">
                    <div className="w-full md:w-6/12">
                      <div className="embla">
                        <div className="embla__viewport embla_a3_landscape">
                          <div className="embla__container">
                            <div className="embla__slide">
                              <div className="embla__lazy-load">
                                <span className="embla__lazy-load__spinner"></span>
                                <img
                                  className="embla__slide__img embla__lazy-load__img"
                                  src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D"
                                  alt="Fluidity - screen print on canvas"
                                  data-src="img/flu2.jpeg"
                                />
                              </div>
                            </div>
                            <div className="embla__slide">
                              <div className="embla__lazy-load">
                                <span className="embla__lazy-load__spinner"></span>
                                <img
                                  className="embla__slide__img embla__lazy-load__img"
                                  src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D"
                                  alt="Fluidity - screen print on canvas"
                                  data-src="img/flu2.jpeg"
                                />
                              </div>
                            </div>
                            <div className="embla__slide">
                              <div className="embla__lazy-load">
                                <span className="embla__lazy-load__spinner"></span>
                                <img
                                  className="embla__slide__img embla__lazy-load__img"
                                  src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D"
                                  alt="Fluidity - screen print on canvas"
                                  data-src="img/flu3.jpeg"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="embla__controls">
                          <div className="embla__buttons">
                            <button
                              className="embla__button embla__button--prev"
                              type="button"
                              disabled=""
                            >
                              <svg className="embla__button__svg" viewBox="0 0 532 532">
                                <path d="M355.66 11.354c13.793-13.805 36.208-13.805 50.001 0 13.785 13.804 13.785 36.238 0 50.034L201.22 266l204.442 204.61c13.785 13.805 13.785 36.239 0 50.044-13.793 13.796-36.208 13.796-50.002 0a5994246.277 5994246.277 0 0 0-229.332-229.454 35.065 35.065 0 0 1-10.326-25.126c0-9.2 3.393-18.26 10.326-25.2C172.192 194.973 332.731 34.31 355.66 11.354Z"></path>
                              </svg>
                            </button>
                            <button
                              className="embla__button embla__button--next"
                              type="button"
                              disabled=""
                            >
                              <svg className="embla__button__svg" viewBox="0 0 532 532">
                                <path d="M176.34 520.646c-13.793 13.805-36.208 13.805-50.001 0-13.785-13.804-13.785-36.238 0-50.034L330.78 266 126.34 61.391c-13.785-13.805-13.785-36.239 0-50.044 13.793-13.796 36.208-13.796 50.002 0 22.928 22.947 206.395 206.507 229.332 229.454a35.065 35.065 0 0 1 10.326 25.126c0 9.2-3.393 18.26-10.326 25.2-45.865 45.901-206.404 206.564-229.332 229.52Z"></path>
                              </svg>
                            </button>
                          </div>
                          <div className="embla__dots"></div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full md:w-6/12 md:pl-3">content</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full max-w-[960px] mx-auto relative" data-type="reveal">
              <div className="collapsible flex flex-row text-slate-900">
                <div className="w-full my-1 px-3 py-2">
                  <p className="text-[1.5rem]">Exhibitions</p>
                </div>
              </div>
              <div className="collapsibleBlock flex flex-row text-gray-900">
                <div className="block">
                  <div className="w-full flex flex-col py-8 mx-auto max-w-full p-3">
                    <div className="">
                      <h3 className="mb-6">Solo Exhibitions</h3>
                      <ul className="cv mb-6">
                        <li>
                          2014 <em>A Wrinkle in Time</em> Literary Notions Bundaberg Regional Arts
                          Gallery, Bundaberg, QLD
                        </li>
                      </ul>
                      <h3 className="mb-6">Group Exhibitions</h3>
                      <ul className="cv mb-6">
                        <li>
                          2013 <em>Urban Screen</em> Chatswood, Sydney, NSW
                        </li>
                        <li>
                          2013 <em>dTV</em> Federation Square, Melbourne, VIC
                        </li>
                        <li>
                          2012 <em>Art After Dark</em> Biennale of Sydney Pier 2/3 Walsh Bay,
                          Sydney, NSW
                        </li>
                        <li>
                          2011 <em>For Arts Sake</em> Sydney College of the Arts, Rozelle, Sydney
                        </li>
                        <li>
                          2010 <em>Memory Flows </em>The Armory, Sydney Olympic Park, NSW
                        </li>
                        <li>
                          2010 <em>Time Travel: Reimagining the Past</em> Tweed River Art Gallery,
                          Murwillumbah NSW
                        </li>
                        <li>
                          2009 <em>DIWO</em> at the Dark Mountain HTTP Gallery, London, UK
                        </li>
                        <li>
                          2009 <em>Intimate</em> Loop Space, Newcastle, NSW
                        </li>
                        <li>
                          2008 <em>Without Boundaries</em> Art Gallery of New South Wales Research
                          Library, Sydney, NSW
                        </li>
                        <li>
                          2006 <em>Soundtoys</em> Watershed Media Centre, Bristol, UK
                        </li>
                        <li>
                          2006 <em>Screenshot(s)</em> Shrewsbury Museum &amp; Art Gallery, UK
                        </li>
                        <li>
                          2005-2006 <em>Net:Reality</em> 20:21 Visual Arts Gallery, Scunthorpe.
                          Shrewsbury Museum &amp; Art Gallery. Q-Arts, Derby. Space4, Peterborough,
                          UK
                        </li>
                        <li>
                          2005 <em>Third Iteration</em> Centre for Electronic Media Art, Monash
                          University, Melbourne, VIC
                        </li>
                        <li>
                          2004 <em>Dissension Convention</em> Postmasters Gallery, New York, USA
                        </li>
                        <li>
                          2003 <em>12:12</em> Cardiff School of Art and Design, Cardiff, UK
                        </li>
                        <li>
                          2003 <em>Skin/Strip</em> Site Gallery, Sheffield, UK
                        </li>
                        <li>
                          2003 <em>where r u ?</em> Group event, performance &amp; internet project.
                          Bristol, UK
                        </li>
                        <li>
                          2002 <em>Mute-Dialogue</em> Watershed Media Centre, Bristol, UK
                        </li>
                        <li>
                          2002 <em>..here nor there.. </em>Kibla Multimedia Center, Maribor,
                          Slovenia
                          <br />
                        </li>
                        <li>
                          2000 <em>Medi@terra</em> Athens, Greece
                        </li>
                      </ul>
                      <h3 className="mb-6">Online projects</h3>
                      <ul className="cv mb-6">
                        <li>
                          2004-2010 <em>VisitorsStudio</em> Live multi user A/V mixing environment -
                          <a
                            href="http://web.archive.org/web/20210411015653/http://www.visitorsstudio.org/"
                            target="_blank"
                          >
                            visitorsstudio.org
                          </a>
                        </li>
                        <li></li>
                        <li>
                          2003 <em>FurtherStudio</em> virtual artist in residence environment with
                          Furtherfield.org -
                          <a href="http://web.archive.org/web/20210411015653/http://www.furtherfield.org/furtherstudio/docs/about.html/">
                            furtherfield.org/furtherstudio
                          </a>
                        </li>
                        <li>
                          2003 <em>Skin/Strip Online</em> interactive online installation with
                          <a
                            href="http://web.archive.org/web/20210411015653/http://furtherfield.org/"
                            target="_blank"
                          >
                            Furtherfield.org
                          </a>
                          and
                          <a
                            href="http://web.archive.org/web/20210411015653/http://www.completelynaked.co.uk/"
                            target="_blank"
                          >
                            Completely Naked
                          </a>
                          - <del>www.skinstrip.net</del>
                        </li>
                        <li>
                          2002 <em>Informer</em> Part of Mute-Dialogue a multimedia projected
                          initiated by Yasser Rashid -
                          <a href="http://web.archive.org/web/20210411015653/http://www.mute-dialogue.com/">
                            www.mute-dialogue.com
                          </a>
                        </li>
                        <li>
                          1999-2003 <em>..here nor there..</em> International Artists Collaboration
                          -
                          <a href="http://web.archive.org/web/20210411015653/http://www.herenorthere.net/">
                            www.herenorthere.net
                          </a>
                        </li>
                      </ul>
                      <h3 className="mb-6">Selected Performances</h3>
                      <ul className="cv mb-6">
                        <li>
                          2006 <em>Month of Sundays</em> Watershed Media Centre, Bristol, UK
                        </li>
                        <li>
                          2004 <em>Dissension Convention</em> online performance curated by
                          <a
                            href="http://web.archive.org/web/20210411015653/http://furtherfield.org/"
                            target="_blank"
                          >
                            Furtherfield.org
                          </a>
                          <a
                            href="http://web.archive.org/web/20210411015653/http://www.furtherfield.org/dissensionconvention/"
                            target="_blank"
                          >
                            furtherfield.org/dissensionconvention
                          </a>
                        </li>
                        <li>
                          2001 <em>Walls Have Ears</em> Audio visual performance with Roger Mills
                          Various Venues, UK
                        </li>
                      </ul>
                      <h3 className="mb-6">Commissions &amp; Residencies</h3>
                      <ul className="cv mb-6">
                        <li>
                          2014 <em>A Wrinkle in Time</em> Bundaberg Regional Arts Gallery,
                          Bundaberg, QLD
                        </li>
                        <li>
                          2000 <em>Web artist in residence</em> for Year of the Artist, South West
                          Arts, UK
                        </li>
                        <li>
                          1997 <em>Web artist in residence</em> DA2 - Digital Arts Development
                          Agency, Bristol, UK
                        </li>
                      </ul>
                      <h3 className="mb-6">Curation</h3>
                      <ul className="cv mb-6">
                        <li>
                          2009 <em>The Garden of Forking Paths</em> - Artists' Computer Games.
                          Western Plains Cultural Centre, May 2013. Bathurst Regional Gallery, March
                          2012. Tin Sheds, University of Sydney, November 2011. Glasshouse Gallery,
                          Port Macquarie, February 2011. Burnie Regional Art Gallery, July 2010.
                          Electrofringe, Loop Space Newcastle&nbsp; 2&nbsp; – 24 October 2009
                          <a
                            href="http://web.archive.org/web/20210411015653/http://www.dlux.org.au/tgofp/"
                            target="_blank"
                          >
                            dlux.org.au/tgofp
                          </a>
                        </li>
                        <li>
                          2001 <em>12-12</em> Live 12 hour show and webcast, featuring national and
                          international performers, sonic and video artists. Cardiff School of Art
                          and Design, Cardiff, UK
                        </li>
                      </ul>
                      <h3 className="mb-6">Awards</h3>
                      <ul className="cv mb-6">
                        <li>
                          2009 <em>Visitors Studio</em> Grand Prize,
                          <a
                            href="http://web.archive.org/web/20210411015653/http://netarts.org/"
                            target="_blank"
                          >
                            Netarts.org
                          </a>
                        </li>
                      </ul>
                      <h3 className="mb-6">Professional Associations</h3>
                      <ul className="cv mb-6">
                        <li>
                          2013 <em>Juror</em>
                          <a
                            href="http://web.archive.org/web/20210411015653/http://www.australiacouncil.gov.au/"
                            target="_blank"
                          >
                            Australia Council for the Arts
                          </a>
                          (Interarts, Digital Programs)
                        </li>
                        <li>
                          2009-2011 <em>Chair/Board member</em>
                          <a href="http://web.archive.org/web/20210411015653/http://dlux.org.au/">
                            dLux Media Arts
                          </a>
                          , Sydney, NSW
                        </li>
                        <li>
                          2000-2008 <em>Technical Director for projects</em>
                          <a href="http://web.archive.org/web/20210411015653/http://furtherfield.org/">
                            Furtherfield.org
                          </a>
                        </li>
                        <li>
                          2000-2001 <em>Contributor</em>
                          <a
                            href="http://web.archive.org/web/20210411015653/http://netartreview.net/"
                            target="_blank"
                          >
                            netartreview.net
                          </a>
                        </li>
                      </ul>
                      <h3 className="mb-6">Publications</h3>
                      <ul className="cv mb-6">
                        <li>
                          2005 <em>Third Iteration</em> Centre for Electronic Media Art, Monash
                          University, Melbourne, VIC
                        </li>
                        <li>
                          2003 <em>The Orbital Poetry Engine</em> : LEONARDO (Vol. 36, No. 4) : MIT
                          Press
                        </li>
                        <li>
                          2003 <em>The Art of War </em>Decode Magazine (Issue 7) UK
                        </li>
                        <li>
                          2003 <em>Poems That Go</em> (Spring/Summer 2003) Internet
                          <a
                            href="http://web.archive.org/web/20210411015653/http://www.poemsthatgo.com/"
                            target="_blank"
                          >
                            www.poemsthatgo.com
                          </a>
                        </li>
                        <li>
                          2003 <em>low-fi - referencing other spaces</em>
                          <del>www.low-fi.org.uk</del>
                        </li>
                        <li>
                          2002 <em>Shaken Identity</em> Decode Magazine (Issue 5) UK
                        </li>
                        <li>
                          2002 <em>The Four Dimensional Monument</em> Fuse Magazine (Volume 25,
                          Number 4) Canada
                        </li>
                        <li>
                          2002
                          <em>Low-Fi guest list : 'click here to skip intro'</em>
                          Internet <del>www.low-fi.org.uk</del>
                        </li>
                        <li>
                          2001
                          <em>365 (Year of the Artist in the South West)</em> Agre Books, UK
                        </li>
                      </ul>
                      <h3 className="mb-6">Teaching</h3>
                      <ul className="cv mb-6">
                        <li>
                          2003 <em>Researcher, Time Based Art</em> Cardiff School of Art and Design,
                          Cardiff, UK
                        </li>
                        <li>
                          1999-2008
                          <em>Senior Lecturer, Graphic Design and Interactive Media</em>
                          School of Art and Design, Bath Spa University, Bath, UK
                        </li>
                      </ul>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="h-[72px]">
              <div className="w-full my-1 px-3 py-2">
                <p className="text-[.7rem] text-slate-500">&#169; 2024 Neil Jenkins</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
