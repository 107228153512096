import Page from "./page.js";
import Wallpaper from "./wallpaper.js";
import Accordion from "./wallpaper.js";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <>
      <Wallpaper></Wallpaper>

      <Page></Page>
      <Accordion
        title="Biography"
        content="I am an artist who works with technology and computer code
                  producing a range of artworks from generative audio video work
                  and interactive digital pieces to physical prints and machine
                  made drawings. I'm fascinated with science and mathematics,
                  design and the art, and these often influence the creation of
                  bodies of work. My work has been exhibited internationally and
                  featured in a number of art and media festivals, publications
                  and academic journals."
      ></Accordion>
    </>
  );
}

export default App;
